import React from 'react';
import { graphql, Link } from 'gatsby';
import PageWithHeader from '../components/pageWithHeader';
import { LinkCard } from '../components/LinkCard';
import style from './team.module.scss';
import Img from 'gatsby-image';
import {TertiaryLinkButton} from '../components/buttons';
import { DataComponent } from '../helpers/dataHelpers';

const categoryOrder = {
  'Leadership': 1,
  'Management': 2,
  'Staff': 3
};

function groupby<T extends unknown, U extends keyof { [K in keyof T]: T[K] extends string ? K : never }>(list: T[], key: U) {
  const map = new Map<T[U], T[]>();
  list.map(val => {
    if (!map.has(val[key]))
      map.set(
        val[key],
        list.filter(data => data[key] === val[key])
      );
  });

  return map;
}


const Team: DataComponent<GatsbyTypes.TeamPageQuery> = ({data}) => {
  const page = data?.contentfulTeamPage;
  const people = data?.allContentfulPerson.edges.flatMap(e => e.node);
  const grouped = Array.from(groupby(people, 'category'))
    .sort(([aKey], [bKey]) => categoryOrder[aKey as string] > categoryOrder[bKey as string] ? 1 : -1)
    .map(([key, list]) => ([key, list.sort((a, b) => (a.order || Number.MAX_VALUE) > (b.order || Number.MAX_VALUE) ? 1 : -1)]) as [typeof key, typeof list]);

  return (
    <PageWithHeader
      title="Team"
      description="Get to know the talented and experienced team at Centeva"
    >
      <article className={style.article}>
        {page && (
          <div className={style.spotlight}>
            <div className={style.spotlightContent}>
              <h2>{page.title}</h2>
              <p>{page.subTitle?.subTitle}</p>
              <TertiaryLinkButton to={`/${page.linkUrl}`}>{page.linkName}</TertiaryLinkButton>
            </div>
            <div className={style.spotlightImage}>
              {page.spotlightImage && <Img fluid={page?.spotlightImage?.localFile?.childImageSharp?.fluid}/>}
            </div>
          </div>
        )}
        <div>
          {Array.from(grouped).map(([key, list]) => (
            <div className={style.category} key={key}>
              <h3>{key}</h3>
              <div className={style.personList}>
                {list.map(person => (
                  <div  className={style.card} key={person?.name}>
                    <LinkCard
                      title={person?.name || ''}
                      subTitle={person?.position}
                      description={person?.shortBio?.shortBio}
                      image={person?.photo}
                      link={person?.bio?.id ? `/bio/${person.slug}` : ''}
                    ></LinkCard>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </article>
      <div className={style.articleFooter}>
        <h3>Forward-focused Solutions. World-class Service.</h3>
        <Link to={`/contact/`}><button>Contact</button></Link>
      </div>
    </PageWithHeader>
  );
};

export default Team;

export const data = graphql`
query TeamPage {
  contentfulTeamPage(name: { eq: "team-page" }) {
    linkName
    linkUrl
    title
    subTitle {
      subTitle
    }
    spotlightImage {
      localFile {
        childImageSharp {
          fluid(maxWidth: 900) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  },
  allContentfulPerson(filter: { category: { nin: "Alumni" } }) {
    edges {
      node {
        name
        slug
        order
        photo {
          contentful_id
          localFile {
            childImageSharp {
              fluid(maxWidth: 900) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        category
        position
        bio {
          id
        }
        shortBio {
          shortBio
        }
      }
    }
  }
 }
`;
