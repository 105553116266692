import React from 'react';
import style from './LinkCard.module.scss';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import classNames from '../helpers/classNames';
import { Icon } from './icon';
import { PropComponent } from '../helpers/dataHelpers';

export type LinkCardProps = {
    title: string,
    subTitle?:string,
    description?:string,
    link?: string,
    image?: GatsbyTypes.ContentfulAsset
};

export const LinkCard: PropComponent<LinkCardProps> = ({ title, subTitle, description, link, image }) => {
    const card = () => <div className={style.cardContent}>
        {image && <Img className={style.cardImage} fluid={image?.localFile?.childImageSharp?.fluid}/>}
        <div className={style.cardDetails}>
            <h3 className={style.cardTitle}>{title}</h3>
            { subTitle && <p className={style.cardSubTitle}>{subTitle}</p>}
        </div>
    </div>;

    return (<div className={classNames(style.card, link ? style.cardHover : '')}>
        {link ?
            <Link  to={link}>{card()}</Link> :
            <div tabIndex={0}>
                {description && <div className={style.cardDescription}>
                    <div className={style.descriptionIcon}><div className={style.iconBackground}><Icon>keyboard_arrow_down</Icon></div></div>
                    <p>{description}</p>
                </div>}
                {card()}
            </div>}
    </div>);
}
